
export default {
  name: 'MktNavigationItemParent',
  props: {
    hasChildren: {
      default: false,
      type: Boolean,
    },
    isActive: {
      default: false,
      type: Boolean,
    },
    to: {
      type: String,
      default: null,
    },
  },
  computed: {
    isChildlessClass() {
      if (this.hasChildren) {
        return '';
      }
      return 'ru:navigation__parent--childless';
    },
    isActiveClass() {
      if (this.isExternalLink) {
        return '';
      }
      if (this.isActive) {
        return 'ru:navigation__parent--active';
      }
      return '';
    },
    element() {
      if (this.isExternalLink) {
        return 'a';
      }
      if (this.hasChildren) {
        return 'button';
      }
      return 'nuxt-link';
    },
    type() {
      if (this.hasChildren) {
        return 'button';
      }
      return null;
    },
    isExternalLink() {
      return this.hasChildren ? false : this.to.substr(0, 1) !== '/';
    },
    newTabAttributes() {
      if (this.isExternalLink) {
        return {
          target: '_blank',
          rel: 'noreferrer',
        };
      }
      return null;
    },
  },
};
