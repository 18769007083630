
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MktHeader',
  data() {
    return {
      hasScrolled: false,
      mobileMenuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['hasOffer', 'isReturning']),
    hasBlogLayout() {
      return this.$route.fullPath.includes('/blog');
    },
    logoAriaLabel() {
      return this.hasBlogLayout ? 'blog' : 'homepage';
    },
    logoLink() {
      return this.localePath(this.hasBlogLayout ? '/blog' : '/');
    },
    text() {
      return this.hasBlogLayout
        ? 'Use Safewill to write your own will online. Today.'
        : 'Australia’s most trusted will writing platform.';
    },
    buttonLabel() {
      return this.isReturning ? 'Finish your Will' : 'Get started';
    },
    isPoaPage() {
      return this.$route.path === this.localePath('/power-of-attorney');
    },
    poaLink() {
      return this.localePath('/power-of-attorney');
    },
  },
  watch: {
    activeLinkIndex(to, from) {
      this.setTransitionDirection({ from, to });
    },
  },
  mounted() {
    this.$scroll.trigger();
  },
  methods: {
    ...mapActions(['setTransitionDirection']),
    toggleMobileMenu() {
      this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
      document.documentElement.classList.toggle(
        '--mobile-menu-opened',
        this.mobileMenuIsOpen
      );
    },
    onScroll(scrollTop) {
      document.documentElement.classList.toggle(
        '--has-scrolled',
        scrollTop > 0
      );
    },
  },
};
