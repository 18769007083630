
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MktNavigation',
  props: {
    mobileMenuIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNavigationItem: null,
      activeNavigationItemIsParent: false,

      hasScrolled: false,
    };
  },
  computed: {
    ...mapGetters(['hasOffer', 'isReturning', 'webfontActive']),
    navigationItems() {
      return [
        {
          label: 'components.navigations.items.estatePlanning',
          children: [
            {
              label: 'components.navigations.items.wills',
              to: this.localePath('/wills'),
            },
            {
              label: 'components.navigations.items.powerOfAttorney',
              to: this.localePath('/power-of-attorney'),
            },
            {
              label: 'components.navigations.items.legalAdvice',
              to: this.localePath('/legal-advice'),
            },
            {
              label: 'components.navigations.items.howItWorks',
              to: this.localePath('/how-it-works'),
            },
            {
              label: 'components.navigations.items.pricing',
              to: this.localePath('/pricing'),
            },
          ],
        },
        ...(this.$i18n.locale === 'au'
          ? [
              {
                label: 'components.navigations.items.probate',
                children: [
                  {
                    label: 'components.navigations.items.probate',
                    to: 'https://safewilllegal.com.au/probate',
                  },
                  {
                    label:
                      'components.navigations.items.lettersOfAdministration',
                    to: 'https://safewilllegal.com.au/letters-of-administration',
                  },
                ],
              },
            ]
          : []),
        {
          label: 'components.navigations.items.resources',
          children: [
            {
              label: 'components.navigations.items.blog',
              to: this.localePath('/blog'),
            },
            {
              label: 'components.navigations.items.aboutUs',
              to: this.localePath('/about-us'),
            },
          ],
        },
        {
          label: 'components.navigations.items.forCharities',
          to: this.localePath('/charities'),
        },
        {
          label: 'components.navigations.items.forAdvisers',
          to: 'https://get.safewill.com/advisers',
        },
      ];
    },
    isPoaPage() {
      return this.$route.path === this.localePath('/power-of-attorney');
    },
    poaLink() {
      return `${this.$config.safewillAppUrl}${this.localePath(
        `/get-started?redirect=/poa`
      )}`;
    },
    flatNavigationItemArray() {
      return this.navigationItems.flatMap((parent) => {
        return parent.to
          ? parent.to
          : parent.children.flatMap((child) => child.to);
      });
    },
    activeLinkIndex() {
      return this.flatNavigationItemArray.findIndex(
        (link) => link === this.$route.path
      );
    },
    buttonLabel() {
      return this.$t(
        this.isReturning
          ? 'common.buttons.finishYourWill'
          : 'common.buttons.getStarted'
      );
    },
    hasBlogLayout() {
      return this.$route.fullPath.includes('/blog');
    },
    logoAriaLabel() {
      return this.hasBlogLayout ? 'blog' : 'homepage';
    },
    logoLink() {
      return this.localePath(this.hasBlogLayout ? '/blog' : '/');
    },
    loginLink() {
      return `${this.$config.safewillAppUrl}${this.localePath('/log-in')}`;
    },
    navigationIsExpandedClass() {
      return this.activeNavigationItemIsParent ? '--parent-menu-open' : '';
    },
    mobileMenuIsOpenClass() {
      return this.mobileMenuIsOpen ? '--mobile-menu-open' : '';
    },
  },
  watch: {
    activeLinkIndex(to, from) {
      this.setTransitionDirection({ from, to });
    },
    $route() {
      if (this.mobileMenuIsOpen) this.$emit('toggleMobileMenu');
      this.identifyActiveNavigation();
    },
    webfontActive() {
      if (this.webfontActive) {
        this.measureNavigationParents();
      }
    },
  },

  mounted() {
    this.identifyActiveNavigation();
    if (this.webfontActive) {
      this.measureNavigationParents();
    }
  },
  methods: {
    ...mapActions(['setTransitionDirection']),
    measureNavigationParents() {
      let cumulativeOffset = 0;
      for (let index = 0; index < this.navigationItems.length; index++) {
        const parentRef = this.$refs[`navigation__parent--${index}`];
        const childRef = this.$refs[`navigation__children--${index}`];
        if (childRef) {
          childRef[0].style.setProperty('--offset', `${cumulativeOffset}px`);
        }
        cumulativeOffset += parentRef[0].$el.offsetWidth;
      }
    },
    activeNavigationItemClass(item) {
      return this.isNavigationItemActive(item)
        ? 'ru:navigation__parent--active'
        : '';
    },
    activeNavigationChildrenClass(item) {
      return this.isNavigationItemActive(item)
        ? 'ru:navigation__children--active'
        : '';
    },
    isNavigationItemActive(item) {
      return item === this.activeNavigationItem;
    },
    identifyActiveNavigation() {
      const path = this.$route.path;
      if (path !== this.localePath('/')) {
        const activeNavigationItem = this.navigationItems.find((item) => {
          return (
            item.to === path ||
            item.children?.find((child) => child.to === path)
          );
        });
        if (activeNavigationItem) {
          this.setActiveNavigationItem(
            activeNavigationItem.label,
            !!activeNavigationItem.children
          );
        }
      } else {
        this.setActiveNavigationItem(null, false);
      }
    },
    setActiveNavigationItem(item, hasChildren) {
      this.activeNavigationItem = item;
      this.activeNavigationItemIsParent = hasChildren;
    },
  },
};
